/**
 * Author:    Kevin Matthews
 * Created:   02-25-2021
 *
 * Source code for the Penn State Tuba Euphonium webiste, created with GatsbyJS for deployment to Netlify
 *
 * https://kmatthews.dev/
 *
 **/

import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SlideComponent from '../components/slider'

import Facebook from '../images/social-media-icons/facebook.png'
import Instagram from '../images/social-media-icons/instagram.png'
import Twitter from '../images/social-media-icons/twitter.png'
import Youtube from '../images/social-media-icons/youtube.png'

import '../styles/layout-styles.css'
import '../styles/home-styles.css'

const IndexPage = ({ data }) => {
  const [posts, setPosts] = useState([])
  const [isMobile, setIsMobile] = useState(true)

  // Is mobile query
  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    let isMobile = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem)
    })

    setIsMobile(isMobile)
  }, [])

  // Get blogs (get only 2 most recent to not clutter homepage?)
  useEffect(() => {
    const posts = data.allMarkdownRemark.nodes

    const blogElements = []

    posts.forEach((post) => {
      if (post.frontmatter.showOnHomepage) {
        blogElements.push(
          <div className='upcomingItem'>
            {!isMobile && (
              <>
                <div style={{ minWidth: '40%', paddingRight: '2%' }}>
                  <Img fluid={post.frontmatter.image.childImageSharp.fluid} />
                </div>
                <div className='upcomingContent'>
                  <span className='blogTitle'>{post.frontmatter.title}</span>
                  <span className='blogDate'>
                    {post.frontmatter.date} <br />
                    {post.frontmatter.time}
                  </span>
                  {post.frontmatter.description !== '' && (
                    <p className='blogDate'>{post.frontmatter.description}</p>
                  )}
                  {!post.frontmatter.useLink && (
                    <Link className='blogLink' to={post.frontmatter.slug}>
                      {post.frontmatter.linkTitle}
                    </Link>
                  )}
                  {post.frontmatter.useLink && (
                    <a
                      className='blogLink'
                      href={post.frontmatter.link}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {post.frontmatter.linkTitle}
                    </a>
                  )}
                </div>
              </>
            )}
            {isMobile && (
              <div className='upcomingContentMobile'>
                <span className='blogTitle' style={{ textAlign: 'start' }}>
                  {post.frontmatter.title}
                </span>
                <span className='blogDate' style={{ textAlign: 'start' }}>
                  {post.frontmatter.date} <br />
                  {post.frontmatter.time}
                </span>
                {post.frontmatter.description !== '' && (
                  <p className='blogDate' style={{ textAlign: 'start' }}>
                    {post.frontmatter.description}
                  </p>
                )}
                {!post.frontmatter.useLink && (
                  <Link className='blogLink' to={post.frontmatter.slug}>
                    {post.frontmatter.linkTitle}
                  </Link>
                )}
                {post.frontmatter.useLink && (
                  <a
                    className='blogLink'
                    href={post.frontmatter.link}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {post.frontmatter.linkTitle}
                  </a>
                )}
              </div>
            )}
          </div>
        )
      }
    })
    setPosts(blogElements)
  }, [data, isMobile])

  return (
    <Layout>
      <div className='homeImageWrapper'>
        <SlideComponent />
      </div>
      <div className='homeIconWrapper'>
        <img
          onClick={() =>
            window.open('https://www.facebook.com/PSUTubaEuph', '_newtab')
          }
          src={Facebook}
          className='homeIcon'
        />
        <img
          onClick={() =>
            window.open('https://www.instagram.com/psutubaeuph/', '_newtab')
          }
          src={Instagram}
          className='homeIcon'
        />
        <img
          onClick={() =>
            window.open('https://twitter.com/PSUTubaEuph', '_newtab')
          }
          src={Twitter}
          className='homeIcon'
        />
        <img
          onClick={() =>
            window.open('https://www.youtube.com/user/PSUTubaEuph', '_newtab')
          }
          src={Youtube}
          className='homeIcon'
        />
      </div>
      <div className='text-3xl my-6 text-nittanyNavy text-center md:my-14 lg:text-6xl'>
        Upcoming
      </div>
      <div className='blogWrapperHome'>
        {posts.length === 0 && (
          <span className='blogDate' style={{ textAlign: 'center' }}>
            Looks like there's no upcoming events. Check out our previous events{' '}
            <Link to='/events'>here</Link>.
          </span>
        )}
        {posts}
      </div>
    </Layout>
  )
}

export default IndexPage

// Set frontmatter.useLink to true to use frontmatter.link instead of frontmatter.slug for the button. This is for links to social media instead of using the onsite blog.
// frontmatter.linkTitle is used for both

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: ASC }) {
      nodes {
        excerpt
        frontmatter {
          slug
          date(formatString: "MMMM DD, YYYY")
          time
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 800, maxHeight: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          link
          useLink
          linkTitle
          showOnHomepage
        }
      }
    }
  }
`
