// =================== https://github.com/express-labs/pure-react-carousel#readme =================== //

import React, { useEffect, useState } from 'react'

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'

import home1 from '../images/home/home1.jpg'
import home2 from '../images/home/home2.jpg'
import home3 from '../images/home/home3.jpg'
import home4 from '../images/home/home4.jpg'
import home5 from '../images/home/home5.jpg'
import home6 from '../images/home/home6.jpg'

import '../styles/home-styles.css'

const SlideComponent = (props) => {
  const [isMobile, setIsMobile] = useState(false)

  // Is mobile query
  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    let isMobile = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem)
    })

    setIsMobile(isMobile)
  }, [])

  return (
    <CarouselProvider
      naturalSlideWidth={500}
      naturalSlideHeight={isMobile ? 400 : 370}
      totalSlides={6}
      visibleSlides={isMobile ? 1 : 3}
      infinite={true}
      classNameAnimation='sliderAnimation'
    >
      <Slider>
        <Slide index={0}>
          <img src={home1} className='sliderImage' />
        </Slide>
        <Slide index={1}>
          <img src={home5} className='sliderImage' />
        </Slide>
        <Slide index={2}>
          <img src={home2} className='sliderImage' />
        </Slide>
        <Slide index={3}>
          <img src={home3} className='sliderImage' />
        </Slide>
        <Slide index={5}>
          <img src={home6} className='sliderImage' />
        </Slide>
        <Slide index={4}>
          <img src={home4} className='sliderImage' />
        </Slide>
      </Slider>
      <div className='arrows' style={{ marginTop: '2%' }}>
        <ButtonBack>
          <NavigateBeforeIcon style={{ fontSize: '50px' }} color='action' />
        </ButtonBack>
        <ButtonNext>
          <NavigateNextIcon style={{ fontSize: '50px' }} color='action' />
        </ButtonNext>
      </div>
    </CarouselProvider>
  )
}

export default SlideComponent
